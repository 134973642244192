const sortUploadsByCategory = arr =>
  arr.reduce(
    (acc, curr) => {
      // Remove any whitespace which might be on the category from the CMS
      const currentCategory = curr.category.trim();
      acc[currentCategory] = acc[currentCategory] || [];
      acc[currentCategory].push({ ...curr, category: currentCategory });

      return acc;
    },
    {
      Jaarverslag: [],
      Organogram: [],
      'Ondersteuningsplan (per 4 jaar)': [],
      'Andere downloads': [],
    }
  );

export default sortUploadsByCategory;
