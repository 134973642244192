import React from 'react';
import PropTypes from 'prop-types';
import Section from '@greenberry/salal/components/Section';
import Introduction from '@greenberry/salal/components/Introduction';
import DownloadsList from '@greenberry/salal/components/DownloadsList';

import PartnershipLayout from '../../../components/PartnershipLayout';
import sortUploadsByCategory from '../../../utils/sortUploadsByCategory';

const Downloads = props => {
  const {
    pageContext: { dato, partnership, copy, sector, ...pageContext },
  } = props;
  const _partnership = Object.assign({}, dato, partnership);

  return (
    <PartnershipLayout
      partnership={_partnership}
      sector={sector}
      seoMetaTags={copy.seoMetaTags}
      {...pageContext}
    >
      <Section size="1">
        <Introduction title={copy.heading} text={copy.intro} />
      </Section>
      <Section size="1">
        <DownloadsList items={sortUploadsByCategory(_partnership.uploads)} />
      </Section>
    </PartnershipLayout>
  );
};

Downloads.propTypes = {
  pageContext: PropTypes.shape({
    copy: PropTypes.shape({
      heading: PropTypes.string.isRequired,
      intro: PropTypes.string.isRequired,
      seoMetaTags: PropTypes.shape({}).isRequired,
    }),
    dato: PropTypes.shape({
      name: PropTypes.string,
      slug: PropTypes.string,
      email: PropTypes.string.isRequired,
    }).isRequired,
    partnership: PropTypes.shape({
      city: PropTypes.string.isRequired,
      address: PropTypes.string.isRequired,
      zipcode: PropTypes.string.isRequired,
      website: PropTypes.string.isRequired,
    }).isRequired,
    sector: PropTypes.string.isRequired,
  }).isRequired,
};

export default Downloads;
